import React from "react";
// import CountryDropdown from './common/CountryDropdown';
// import dansolImg from '../images/dansol.png';
import LoginForm from "./LoginForm";
import LoginBar from "./LoginBar";
import parentIcon from "../icons/parentIcon.png";
import staffIcon from "../icons/staffIcon.png";
import studentIcon from "../icons/studentIcon.png";
import { motion } from "framer-motion";
import { GoogleIcon, AppleIcon } from "./common/storeIcon";
import { toTitleCase } from "../Utils/titleCase";

const landingParentVariants = {
  hidden: {
    opacity: 0,
    // transition : {
    //     staggerChildren : 0.2
    // }
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "spring",
      mass: 0.4,
      damping: 8,
      //   staggerChildren: 0.2,
      when: "beforeChildren",
    },
  },
};

const childVariants = {
  hidden: {
    opacity: 0,
    y: -20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const LoginInputs = ({
  loginBar1,
  loginBar2,
  loginBar3,
  host,
  person,
  schLogo,
  schName,
}) => {
  return (
    <>
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        className="hidden lg:flex justify-end w-full items-center"
      >
        {/* <CountryDropdown /> */}

        <motion.div
          animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0 }}
          transition={{ duration: 0.5 }}
          className="flex mb-2 flex-col lg:flex lg:flex-row lg:space-x-3 lg:space-y-0 "
        >
          <GoogleIcon userHost={host} />
          <AppleIcon userHost={host} />
        </motion.div>
      </motion.div>
      <motion.div
        variants={landingParentVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={`${
          host === "enugustate.edves.net"
            ? "lg:w-3/4"
            : ""
        }'mt-16 pb-8 w-full `}
      >
        <div
          className={`${
            host === "enugustate.edves.net"
              ? "mt-3"
              : "mt-3 w-24 h-24"
          } mx-auto lg:mx-0 lg:mt-8`}
          style={{ width: "5rem", height: "5rem" }}
        >
          <img src={schLogo} alt="School logo" className="w-full h-full" />
        </div>
        <motion.div
          variants={childVariants}
          className={`${
            host === "enugustate.edves.net"
              ? "text-greeColor"
              : "text-white"
          } text-h2 lg:text-headingDark text-center lg:text-left leading-9 mt-2 font-bold`}
        >
          {window.location.host === 'rbschool.edves.net' ? schName : toTitleCase(schName)}
        </motion.div>
        <motion.div
          variants={childVariants}
          className={`${
            host === "enugustate.edves.net"
              ? "text-greeColor"
              : "text-white"
          } text-p lg:text-headingDark mt-2 text-center lg:text-left text-opacity-90 lg:text-opacity-70 font-semibold`}
        >
          Log in as a {person} to access your account{" "}
        </motion.div>
        <motion.div variants={childVariants} className="mt-8">
          <LoginForm
            educator={person === "school staff" && true}
            person={person}
            loginBarClasses="bg-primary w-full rounded-lg cursor-pointer primaryGradient text-white text-p"
            barText={loginBar1}
          />
        </motion.div>
        <motion.div
          variants={childVariants}
          className="mt-4 w-full md:w-3/4 mx-auto lg:w-full"
        >
          <hr className="my-8 text-opacity-70" />
          <div className="space-y-4">
            <LoginBar
              className="bg-secondary block w-full border border-grayBorder hover:border-primary transition-colors duration-300 ease-in-out cursor-pointer rounded-lg text-headingDark text-sm md:text-p"
              text={loginBar2}
              icon={
                loginBar1 === "Log in as a School Staff"
                  ? parentIcon
                  : staffIcon
              }
              opacity
            />
            <LoginBar
              className="bg-secondary block w-full border border-grayBorder hover:border-primary transition-colors duration-300 ease-in-out cursor-pointer rounded-lg text-headingDark text-sm md:text-p"
              text={loginBar3}
              icon={
                loginBar1 === "Log in as a Parent"
                  ? studentIcon
                  : loginBar1 === "Log in as a School Staff"
                  ? studentIcon
                  : parentIcon
              }
              opacity
            />
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default LoginInputs;
